import jwtAxios from 'auth/jwtAxios';

export const sendOrder = async ({message, ...rest}) => {
  const apiToken = process.env.REACT_APP_BOT_TOKEN;
  const chats = JSON.parse(process.env.REACT_APP_CHANNELS || '[]');
  let sendingMessage = `${message}
`;

  const entries = Object.entries(rest || {});
  entries.forEach(([key, value]) => {
    sendingMessage += `
${key}: ${value}`;
  });

  await jwtAxios.post(`${process.env.REACT_APP_API_URL}api/amo/create-lead`,null, {
    params:{name: rest.fullName,
      phone_number: rest.phoneNumber,
      region: rest.region,
      cource:  message,}
  });

  for (const chat of chats) {
    await jwtAxios.post(`https://api.telegram.org/bot${apiToken}/sendMessage`, {
      chat_id: chat,
      text: sendingMessage,
      parse_mode: 'HTML',
    });
  }
};
