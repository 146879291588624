import NewsProfile from "components/NewsProfile";
import Title from "components/Title";
import { MainPropType } from "types/PropTypes";
import { NewsType } from "../NewsList/news";
import { useRef } from "react";
import "./newstitle.style.scss";
import useIntersectionObserver from "utils/InterSectionObserver";

function SingleNewsTitle({ style, news }: MainPropType & { news: NewsType }) {
  const ref = useRef<HTMLDivElement>(null)
  const entity = useIntersectionObserver(ref,{})
   
  return (
    <div style={style} className={`single-new-section ${entity?.isIntersecting && "active"}`} ref={ref}>
      <Title>New pricing for GitLab Premium</Title>
      <NewsProfile news={news} />
    </div>
  );  
}

export default SingleNewsTitle;
