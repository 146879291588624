import useIntersectionObserver from "utils/InterSectionObserver";
import "./title.style.scss";

import React, { useRef } from "react";
import { useCursorContext } from "context/Cursor";

function TitleBody({ title, texts }: { title?: string; texts?: string[] }) {
  const ref = useRef<HTMLDivElement>(null)
  const entity = useIntersectionObserver(ref ,{})
  const {functions} = useCursorContext()

  return (
    <div className={`title-body ${entity?.isIntersecting && "active"}`} ref={ref}>
      <p className="giant-body" style={{paddingBottom:40}}{...functions}>{title} </p>

      <div className="small-body">
        {texts?.map((text: string, i) => (
          <div key={i} style={{ width: "100%" }}>
            {text} <br />
            <br />
          </div>
        ))}
      </div>
    </div>
  );
}

export default TitleBody;
