import "./profile.style.scss";

import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineInstagram } from "react-icons/ai";

function Profile({src,...rest}:{src:string}) {
  return (
    <div className="profile-comp" {...rest}>
      <img src={src } alt="" />
      {/* <Link to="/" className="icon">
        <AiOutlineInstagram className="social" />
      </Link> */}
    </div>
  );
}

export default Profile;
