import Button from "components/Button";
import Input from "components/Input";
import "./form.style.scss";
import { MainPropType } from "types/PropTypes";
import Spin from "components/Spinner";
import { useState } from "react";
import jwtAxios from "auth/jwtAxios";
import { getCourse } from "utils/functions/onload";
import { useParams } from "react-router-dom";
import { log } from "console";
import SuccessData from "components/SuccessData";
import { CourseType } from "data/courses";
import { useLocaleActions } from "locale/LocaleProvider";
import { sendOrder } from "utils/sendOrder";
import { addresses } from "data/addresses";

function FormOrder({
  children,
  course,
}: { course: CourseType } & MainPropType) {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { getLocaleTranslate, getQueryLang } = useLocaleActions();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.currentTarget);
    e.currentTarget.reset();

    sendOrder({
      message:
         `ЗаписатЬся На Курс - ${course.title}`,
      ...Object.fromEntries(formData.entries()),
    })
      .finally(() => {
        setLoading(false);
      })
      .then(() => setSuccess(true))
      .catch(console.log);
  };
  return success ? (
    <SuccessData type="dark" />
  ) : (
    <div className="order-form-section padding-block" id="order">
      {children}
      <form action="" onSubmit={handleSubmit}>
        <Input
          placeholder={getLocaleTranslate("freeconsultation.name")}
          type="text"
          name="fullName"
          className="input"
          required
        />
        <Input
          placeholder={getLocaleTranslate("freeconsultation.phone")}
          type="number"
          name="phoneNumber"
          className="input"
          required
        />
        <Input
          placeholder={getLocaleTranslate("freeconsultation.place")}
          type="select"
          name="region"
          className="input"
          required
           
        >
          <option value="" style={{color:"black"}}>
            {getLocaleTranslate("freeconsultation.place")}
          </option>
          {addresses.map((region) => 
            <option value={region.name} style={{color:"black"}}>
              {getQueryLang(region.name,region.name_ru    )}
            </option> 
         )}
        </Input>

        <Button style={{ maxWidth: "250px" }}>
          {getLocaleTranslate("freeconsultation.button")}
        </Button>
      </form>{" "}
      <Spin loading={loading} style={{ position: "absolute", zIndex: 1 }} />
    </div>
  );
}

export default FormOrder;
