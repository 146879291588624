import { useCursorContext } from "../../context/Cursor";
import useIntersectionObserver from "../../utils/InterSectionObserver";
import React, { useRef } from "react";
import "./words.style.scss";

function AnimateWords({ word, to = "right" }: { word: string; to?: string }) {
  const { functions } = useCursorContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});

  return (
    <div
      className={
        "animate-words-outer " + (entry?.isIntersecting ? "active" : "")
      }
      ref={ref}
    >
      <div className={"animate-words-inner " + to} {...functions}>
        <h1>{word}</h1>
        <h1>{word}</h1>
        <h1>{word}</h1>
        <h1>{word}</h1> 
        <h1>{word}</h1>  
         
      </div>
    </div>
  );
}

export default AnimateWords;
