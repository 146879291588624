import "./newsbody.style.scss";
import React from "react";

function NewsBody() {
  return (
    <div className="news-body-section">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt modi
      corporis minus, neque similique illum, maiores laborum aperiam hic numquam
      libero, impedit iusto? At inventore earum non praesentium quae. Ipsa
      possimus officia nisi sint eum quam voluptatibus tempora quod architecto.
      Ipsam error distinctio perspiciatis nemo inventore. Est quidem odit dolor
      quae, error pariatur omnis ad ex mollitia et natus amet officiis at,
      dolores sapiente quaerat illum minus voluptatibus ipsam tempore ea,
      asperiores perferendis? Alias voluptatem deleniti, voluptatibus ab,
      voluptas nam iste accusamus nihil culpa reprehenderit nostrum! Eum veniam,
      nemo culpa odio enim necessitatibus hic eveniet temporibus eos commodi, a
      quas distinctio omnis molestias cumque saepe, officiis doloremque amet
      assumenda iste optio voluptatum. Consectetur officia aliquam et? Debitis
      quas aperiam nostrum harum aliquid earum soluta obcaecati tempore,
      consectetur vero commodi perspiciatis explicabo maiores amet laborum
      dolore exercitationem delectus natus dicta provident ipsa ipsum quaerat
      vitae! Asperiores unde voluptate praesentium aspernatur alias nesciunt
      ipsa quidem sint nulla beatae magni, nam minus ut amet aliquam, architecto
      vero quasi delectus fugit sunt facilis ratione! Cumque ducimus repellendus
      odio consequuntur corporis expedita nobis corrupti! Minus error quasi
      ducimus sint, cupiditate deserunt eos voluptatum fuga voluptates vitae eum
      necessitatibus dignissimos velit ea aliquid illum voluptate. Eum dolor
      saepe cum sequi quisquam laboriosam possimus necessitatibus distinctio ut
      cupiditate culpa nihil deserunt aliquam, corrupti dolore quidem alias qui
      maxime beatae delectus perspiciatis! Neque, accusamus obcaecati. Similique
      nulla temporibus cum vitae, quibusdam officia harum, totam reiciendis
      optio placeat, est voluptate ullam. Vel labore, non sunt impedit earum
      deleniti ratione quibusdam error magni quo doloremque, amet architecto
      atque incidunt enim? Accusantium quo velit fugiat id perspiciatis nesciunt
      error nemo numquam porro rerum beatae quibusdam officiis, iste odit, unde
      quos ratione. Exercitationem sequi possimus officiis deserunt, quaerat
      quia ut voluptatem molestiae aliquam voluptatum, nemo libero natus
      consequatur. Odio, quisquam ratione. Excepturi accusantium placeat nulla
      dolore odio sed iste. Incidunt consequatur labore laboriosam dolor
      accusantium? Eveniet tempore expedita illo, ipsum voluptatum itaque aut
      adipisci neque exercitationem error hic facilis. Corrupti tempora, magni
      minus ut quidem quibusdam repellat, placeat hic maiores suscipit officia
      possimus repudiandae similique vitae sunt praesentium nihil maxime dolores
      a animi impedit magnam reprehenderit! Odio unde, sapiente voluptatem sint
      nulla, doloremque iure dicta veniam laudantium quos, omnis ipsum harum.
      Beatae id enim mollitia et illo earum dolore eum fugit, suscipit quos
      dicta aliquid ut at perferendis atque qui maiores, pariatur officia ad
      soluta? Pariatur, quaerat ad praesentium nihil expedita tempore
      repellendus saepe explicabo corporis sunt, hic earum sint. Dolorem
      sapiente, laborum quo deserunt, praesentium dicta nisi quam amet, eligendi
      facilis asperiores. Maiores vel quod deserunt consequuntur dolor assumenda
      earum consectetur eligendi eius ad mollitia harum officiis ex quam odio
      aperiam porro dolore, doloremque et. Qui aut eius, vel unde placeat, ex
      nisi quidem accusamus error aliquam labore necessitatibus! Sed vero
      debitis adipisci explicabo omnis, fugiat similique quos, tempore laborum
      ratione praesentium harum et non rem architecto suscipit officiis
      voluptate distinctio! Veritatis nostrum non, placeat quibusdam labore
      doloremque molestias voluptatum ullam rerum animi obcaecati tenetur
      corrupti odio possimus in, eveniet, recusandae dicta praesentium?
      Repudiandae earum commodi rerum repellat omnis? Mollitia, maxime itaque
      dignissimos unde ex, quisquam autem quae fugit enim eos atque sed non
      voluptatum quis magni quia. Impedit vitae neque fugiat earum harum
      nesciunt odit delectus voluptas, aperiam consectetur, expedita dicta
      veniam officia non optio debitis dolorum eius dolores id magni? Eveniet
      incidunt, molestias blanditiis obcaecati repudiandae saepe excepturi
      perspiciatis ex nobis consequuntur, quisquam delectus dolorum eaque
      aliquam ipsa nisi ut et harum fuga suscipit eligendi. Quisquam dolorem
      assumenda ex, eligendi minima qui commodi facilis ipsam esse quia laborum
      nesciunt alias voluptates harum minus, voluptatum nam praesentium
      inventore asperiores nobis sequi, cum laboriosam! Illo quasi quibusdam
      molestias magni qui velit impedit asperiores totam corporis, dolores
      architecto quaerat inventore labore consequuntur neque laborum facilis
      suscipit ex debitis, cupiditate reiciendis. Asperiores consectetur tempora
      voluptate dolor illo dignissimos omnis, itaque voluptatibus. Consequatur
      architecto suscipit molestiae eius tempora maiores quo quidem
      exercitationem cum id perspiciatis voluptatibus, quos labore nostrum
      similique magni dolor ullam corrupti? Libero, commodi. Tenetur earum esse,
      impedit eos nemo nulla deleniti aperiam! Animi officia temporibus
      laboriosam, sunt sit vero dolorum. Quis ipsum sequi quos mollitia,
      veritatis ab? Laboriosam natus fugiat molestias quas quod amet alias
      blanditiis nisi corrupti animi, consectetur facilis reiciendis laudantium
      voluptate repellendus recusandae inventore debitis quo. Consectetur, odit
      maxime pariatur nam deserunt dolorem eum voluptas aspernatur, ea modi
      sequi eaque mollitia, magni rem reiciendis eius! Laudantium similique
      eaque nobis officia accusantium, obcaecati amet, est explicabo omnis
      provident error illum molestiae earum vitae at debitis repudiandae natus
      ab sapiente vel harum, libero voluptate! Pariatur sed facilis ab error
      odio qui nobis temporibus commodi dolorum beatae voluptate minima amet
      dolor debitis placeat corporis, iusto fugit molestiae autem eveniet?
      Perferendis recusandae blanditiis, optio, architecto doloribus voluptas
      aut iure dolore nisi praesentium odit excepturi esse consequuntur alias
      quod repellat eius cupiditate autem possimus dignissimos molestias
      veritatis similique facilis? Quidem deserunt quaerat, doloremque similique
      voluptate excepturi sed perspiciatis optio hic quo doloribus labore ipsa
      maiores natus, eius neque sunt adipisci non vero voluptas commodi
      temporibus animi quia. Magnam veritatis, ullam, et itaque corporis
      blanditiis saepe, natus dolores vitae tempora architecto! Repudiandae
      possimus magni, minus consequuntur rem itaque pariatur veritatis sit
      dicta, eius doloribus adipisci ipsa alias sint. Quas nobis debitis hic ad
      quibusdam odio aliquid, quidem blanditiis quia. Ut laudantium dolorem at
      amet dolore corporis eaque, sequi alias, molestiae numquam voluptate
      repudiandae? Maxime fugit omnis facere minus numquam nulla? Tenetur
      voluptate vel nemo enim, eligendi reiciendis aliquam, sequi repudiandae
      natus delectus doloremque cumque quae distinctio possimus ducimus voluptas
      doloribus et nam aliquid ipsam, beatae quos! Quas consectetur voluptates
      sequi nesciunt id aut reprehenderit vel optio, totam odit porro molestias
      ratione quo quidem voluptatibus tempore ullam. Qui quas suscipit est
      repellat aliquid nihil et sit, dolorem hic corrupti totam voluptatum eius
      dolores, rem debitis beatae ex id cumque autem corporis minima! Et, fuga
      voluptates maiores sequi a atque expedita, pariatur aut laborum accusamus
      deleniti dicta. Temporibus natus minus qui tempora magni. Quod sed amet
      odio eveniet necessitatibus sint natus nesciunt obcaecati ipsam. Vitae,
      ipsum.
    </div>
  );
}

export default NewsBody;
