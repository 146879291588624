import Title from "components/Title";
import React, { useRef } from "react";
import { useCountUp } from "use-count-up";
import useIntersectionObserver from "utils/InterSectionObserver";
import "./course.style.scss";
import { CourseType } from "data/courses";
import { useLocaleActions } from "locale/LocaleProvider";

function CourseNumbers({course}:{course:CourseType}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const {   getLocaleTranslate } = useLocaleActions();
  const { value: practice } = useCountUp({
    isCounting: entry?.isIntersecting,
    start: 0,
    end: course.practice,
    duration: 1.5,
  });
  const { value: homework } = useCountUp({
    isCounting: entry?.isIntersecting,
    start: 0,
    end: course.home_work,
    duration: 1.5,
  });
  const { value: projects } = useCountUp({
    isCounting: entry?.isIntersecting,
    start: 0,
    end: course.project_portfolio,
    duration: 1,
  }); 
  return (
    <div className="course-numbers-component" ref={ref}>
      <div>
        <Title>{practice}</Title>
        <p> {getLocaleTranslate("course.number.practice")} </p>
      </div>
      <div>
        <Title>{homework}</Title>
        <p>  {getLocaleTranslate("course.number.homework")}</p>
      </div>
      <div>
        <Title>{projects}</Title>
        <p>  {getLocaleTranslate("course.number.portfolio")}</p>
      </div>
      {/* <div>
        <Title>{value4}</Title>
        <p>практических занятий</p>
      </div> */}
    </div>
  );
}

export default CourseNumbers;
