import Button from "components/Button";
import CircleAnimation from "components/CircleAnimation";
import { Link } from "react-router-dom";
import "./404.style.scss";

function NotFound({text}:{text?:React.ReactNode}) {
  return (
    <div className="notfound-page">
      {" "}
      <CircleAnimation />
      <div className="content">
        <h1>404</h1>
        <p>
          {text || "Lorem ipsum dolor sit amet consectetur. Rhoncus mauris eleifend pellentesque quisque amet quam pulvinar."}
        </p>
        <Link to="/">
           <Button>Назад на главную страницу</Button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
