import "./footer.style.scss";
import { MdOutlinePhone } from "react-icons/md";
import { RiTelegramLine } from "react-icons/ri";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { FaRegPaperPlane } from "react-icons/fa";
import { Link, useSearchParams } from "react-router-dom";
import Hamburger from "components/Hamburger";
import { useCursorContext } from "context/Cursor";
import useIntersectionObserver from "utils/InterSectionObserver";
import { useRef } from "react";
import Logo from "components/Logo";
import { IoLogoTiktok } from "react-icons/io5";
import { useLocaleActions } from "locale/LocaleProvider";

function Footer() {
  const { functions } = useCursorContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const { getLocaleTranslate } = useLocaleActions();
  const [params]= useSearchParams()
  const settings = params.get("settings")

  return (
    <footer ref={ref} className={entry?.isIntersecting ? "active" : ""}>
 
      <div className="top">
        <Link to="/" {...functions}>
          <Logo />
        </Link>
        <div className="socials">
          <Link to="https://www.instagram.com/mitacademyuz?igsh=MTFjNmdtYzQ5djU2eA==" target="_blank">
            <AiOutlineInstagram size={30} />
          </Link>
          <Link to="https://t.me/mitacademyuz" target="_blank">
            <RiTelegramLine size={30} />
          </Link>
          <Link
            to="https://www.facebook.com/profile.php?id=61550105426997&mibextid=JRoKGi"
            target="_blank"
          >
            <TiSocialFacebookCircular size={30} />{" "}
          </Link>
          {/* <Link
            to="https://www.tiktok.com/@upteaapie0f?_r=1&_d=e60j77hc5cld6m&language=en&sec_uid=MS4wLjABAAAAlwMAuiB5meF-Ir14V_aSk9ksj_qC3k3TE2pNSXhniuxJ7sNXfSN8P1iABZoRsrN7&share_author_id=7214041436219425793&source=h5_m&u_code=dgmi7hlj53dj2l&timestamp=1680603913&user_id=6924828201664939014&sec_user_id=MS4wLjABAAAAExZC3qoEjIDkHRM17dsuRUoFQg7F8ykOR2Y0n4S6pNBR2WFnOctXYVsjrpf4B6qe&utm_source=telegram&utm_campaign=client_share&utm_medium=android&share_iid=7217638088586643201&share_link_id=5da424b4-20d5-435b-aa1b-0a658bc3b300&share_app_id=1233&ugbiz_name=Account&ug_btm=b6880%2Cb5836&social_share_type=5"
            target="_blank"
          >
            <IoLogoTiktok size={25} />{" "}
          </Link>
          <Link
            to="https://www.youtube.com/channel/UCF3Fco5fpdooBEG78ov1q9g"
            target="_blank"
          >
            <AiFillYoutube size={30} />{" "}
          </Link> */}
        </div>
      </div>

      <div className="bottom">
       {!!settings || <div className="links">
          <Link to="/courses">
            <Hamburger size={15} /> {getLocaleTranslate("menu.link.courses")}
          </Link>
          <Link to="/about"> {getLocaleTranslate("menu.link.about")} </Link>
          <Link to="/contact"> {getLocaleTranslate("menu.link.contact")} </Link>
        </div>}

        <div className="links">
          <p>{getLocaleTranslate("menu.link.contact")}</p>
          <Link to="tel:+998 (55)515-30-30">
            <MdOutlinePhone size={25} /> +998 (55)515-30-30{" "}
          </Link>
          
        </div>

        <div className="links">
          <p>OOO MRIT </p>
          <Link to="https://maps.app.goo.gl/DKbhXu3fUqoaCqAv5" target="_blank">
            {getLocaleTranslate("footer.address")}
          </Link>
        </div>

        <div className="links">
          <p>{getLocaleTranslate("footer.worktime")}</p>
          <Link to="/" style={{ maxWidth: 135 }}>
            10:00 - 20:00 {getLocaleTranslate("footer.nonestop")}
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
