import Title from "components/Title";
import "./results.style.scss";
import Slider from "react-slick";
import { StudentResultType, studentResult } from "data";
import { Link, useParams } from "react-router-dom";
import { useRef } from "react";
import useIntersectionObserver from "utils/InterSectionObserver";
import { useLocaleActions } from "locale/LocaleProvider";

function StudentsResults({ studentWorks }: { studentWorks: any }) {
  console.log({ studentWorks });

  const settings = {
    infinite: true,
    speed: 500,
    centerMode: true,
    swipeToSlide: true,
    arrows: false,
    //     autoplaySpeed: 1000,
    autoplay: true,
    centerPadding: "10%",
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          centerPadding: "25%",
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerPadding: "10%",
        },
      },
    ],
  };
  const ref = useRef(null);
  const entity = useIntersectionObserver(ref, {});
  const { getLocaleTranslate  } = useLocaleActions();

  return (
    <div
      className={`students-results-section padding-block ${
        entity?.isIntersecting && "active"
      }`}
      ref={ref}
    >
      <Title
        style={{ maxWidth: 700, margin: "0 10% calc(5vw + 20px)" }}
        from="left"
      >
       {getLocaleTranslate("student-works")}  
      </Title>
      <div style={{ position: "relative" }}>
        <Slider {...settings}>
          {studentWorks.map((studentWork: any) => (
            <div>
             {studentWork.link != 0 ? <Link className="link" to={studentWork.link} target="_blank">
                <img
                  src={`${process.env.REACT_APP_API_URL}static/image/${studentWork.image_name}`}
                  alt=""
                />
              </Link> : <div className="link">
                <img
                  src={`${process.env.REACT_APP_API_URL}static/image/${studentWork.image_name}`}
                  alt=""
                />
              </div> 
              
              }
            </div>
          ))}
          {studentWorks?.length < 2 && <div></div>}
        </Slider>
      </div>
    </div>
  );
}

export default StudentsResults;
