import Banner from "components/Banner";
import NewsBody from "components/sections/NewsBody";
import { newsList, NewsType } from "components/sections/NewsList/news";
import SingleNewsTitle from "components/sections/SingleNewsTitle";
 import {   useParams  } from "react-router-dom";

function SingleNews() {
  const { id } = useParams();
  const news = newsList.find((el: NewsType) => el.id === id);

  return news ? (
    <div>
       <SingleNewsTitle news={news} />{" "}
       <Banner src={news.image}/>
       <NewsBody  />
    </div>
  ) : (
   <></>
  );
}

export default SingleNews;
