import { NewsType } from "components/sections/NewsList/news";
import React from "react";
import "./profile.style.scss";

function NewsProfile({news}:{news:NewsType}) {
  return (
    <div className="news-profile-comp">
      <img src={news.user.image} alt="" />
      <span>by</span> <p>{news.user.name} </p>
      <div className="dot"></div> <span>{news.time}</span>
    </div>
  );
}

export default NewsProfile;
