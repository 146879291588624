import MenuButton from "components/MenuButton";
import "./navbar.style.scss";
import { Link, useSearchParams } from "react-router-dom";
import Line from "components/Line";
import Menu from "components/Menu";
import { useCursorContext } from "context/Cursor";
import { useRef, useState, useEffect } from "react";
import Logo from "components/Logo";
import { useLocaleActions } from "locale/LocaleProvider";

function Navigator({
  isActive,
  setIsActive,
}: {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { functions } = useCursorContext();
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const ref = useRef<HTMLHeadElement>(null);
  const { lang, setLocaleLanguage } = useLocaleActions();
  const [params] = useSearchParams();
  const settings = params.get("settings");

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;

      if (window.pageYOffset === 0) {
        ref.current.style.backdropFilter = "blur(0px)";
        console.log(1);
      } else {
        ref.current.style.backdropFilter = "blur(10px)";
      }

      const currentScrollPos = window.pageYOffset;

      ref.current.style.translate = `0 ${
        scrollPosition > currentScrollPos ? 0 : "-100%"
      }`;

      setScrollPosition(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPosition]);
  // console.log(scrollPosition);

  return (
    <>
      <nav ref={ref}>
        <div className={"navbar "}>
          <a  onClick={() => setIsActive(false)}>
            <Logo />
          </a>{" "}
          <div style={{ display: "flex", gap: "2vw" }}>
            {!!settings || (
              <div
                className="menu-button"
                onClick={() => setIsActive(!isActive)}
                {...functions}
              >
                <p>Menu</p>
                <MenuButton className={isActive ? "active" : ""} />
              </div>
            )}
            <div className="languages">
              <p
                className={lang === "uz" ? "active" : ""}
                onClick={() => setLocaleLanguage("uz")}
              >
                Uz
              </p>
              <p
                className={lang === "ru" ? "active" : ""}
                onClick={() => setLocaleLanguage("ru")}
              >
                Ru
              </p>
            </div>
          </div>
        </div>
        <Line className="darker" />
      </nav>
      <Menu
        isActive={isActive}
        {...{
          onClick() {
            setIsActive(false);
          },
        }}
      />
    </>
  );
}

export default Navigator;
