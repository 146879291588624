import styles from "./title.module.scss";

import React, { useRef } from "react";
import { useCursorContext } from "context/Cursor";
import { MainPropType } from "types/PropTypes";
import useIntersectionObserver from "utils/InterSectionObserver";

function Title({
  children,
  style,
  type,
  from,
}: {
  children: React.ReactNode;
  type?: "small" | "middle" | "large";
  from?: "left";
} & MainPropType) {
  const { functions } = useCursorContext();
  const ref = useRef(null);
  const entity = useIntersectionObserver(ref, {});

  return (
    <h1
      className={`${styles.title}  ${type && styles[type]} ${
        from && styles[from]
      } ${entity?.isIntersecting && styles.active}`}
      style={style}
      {...functions}
      ref={ref}
    >
      {children}
    </h1>
  );
}

export default Title;
