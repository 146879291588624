import FormatLessonCard from "components/FormatLessonCard";
import LessonCard from "components/LessonCard";
import Title from "components/Title";
import TitleAndText from "components/TitleAndText";
import TitleWithP from "components/TitleWithP";
import CourseHead from "components/sections/CourseHead";
import ForWhoSection from "components/sections/ForWhoSection";
import FormConsultation from "components/sections/FormConsultation";
import FormOrder from "components/sections/FormOrder";
import SliderInfos from "components/sections/SliderInfos";
import StrongPoints from "components/sections/StrongPoints";
import "./course.style.scss";
import { useLocaleActions } from "locale/LocaleProvider";
import { Navigate, useParams } from "react-router-dom";
import { getCourse } from "utils/functions/onload";
import { useQuery } from "react-query";
import jwtAxios from "auth/jwtAxios";
import { CourseType } from "data/courses";
import CourseStatistics from "components/sections/CourseStatistics";
import { FormatLessonType, LessonType } from "data";
import StudentsResults from "components/sections/StudentsResults";
import Spin from "components/Spinner";
import { Helmet } from "react-helmet";
import image from "../../assets/images/logo.png";

function CoursePage() {
  const { id } = useParams();
  const { getQueryLang, getLocaleTranslate } = useLocaleActions();
  const { data, isLoading, isError } = useQuery(["course", id], () =>
    jwtAxios.get(`${process.env.REACT_APP_API_URL}api/course/get-course/${id}`)
  );

  const course: CourseType = data?.data;
  if (isError) {
    return <Navigate to="/courses" />;
  }

  if (isLoading) {
    return (
      <Spin
        loading={true}
        style={{ position: "unset", height: "100vh", zIndex: "1" }}
      ></Spin>
    );
  }

  return (
    <div className="course">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          MIT Academy | {getQueryLang(course.title, course.title_ru)}
        </title>
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta name="author" content="Your Name" />
        <meta property="og:image" content={image} />

        <meta property="og:locale" content="uz_UZ" />
        <meta property="og:locale:alternate" content="ru_RU" />
        <meta property="og:url" content={window.location.href} />

        <meta property="og:title" content={course.title} />
        <meta property="og:description" content={course.description} />

        <meta property="og:title:alternate" content={course.title_ru} />
        <meta
          property="og:description:alternate"
          content={course.description_ru}
        />
      </Helmet>
      <CourseHead course={course} />
      <CourseStatistics course={course} />
      <ForWhoSection course={course} />
      {course.start_group_rel[0] && (
        <SliderInfos title={getLocaleTranslate("course.coming-groups.title")}>
          {course.start_group_rel.map((lesson: LessonType) => (
            <div>
              <LessonCard lesson={lesson} />
            </div>
          ))}
        </SliderInfos>
      )}
      {course.learn_format_rel[0] && (
        <SliderInfos title={getLocaleTranslate("course.learning-form.title")}>
          {course.learn_format_rel.map((formatLesson: FormatLessonType) => (
            <div>
              <FormatLessonCard formatLesson={formatLesson} />
            </div>
          ))}
        </SliderInfos>
      )}
      <FormOrder course={course}>
        {" "}
        <Title>{getLocaleTranslate("course.order.title1")}</Title> <br />
        <Title style={{ color: "#c0ff03" }}>
          {getLocaleTranslate("course.order.title2")}
        </Title>{" "}
        <p
          style={{ maxWidth: 300, padding: "2vw 0 0" }}
          className="formorder-text"
        >
          {getLocaleTranslate("course.order.text")}
        </p>
      </FormOrder>
      <StudentsResults studentWorks={course.student_work_rel} />
      <FormConsultation
        h1={getLocaleTranslate("form.freelesson.title")}
        p={getLocaleTranslate("form.freelesson.text")}
        orderType={`БЕСПЛАТНЫЙ ОТКРЫТЫЙ УРОК - ${course.title}`}
      />

      <StrongPoints style={{ margin: "10vw 0" }} />
    </div>
  );
}

export default CoursePage;
