export const addresses = [
  {
    name: "Toshkent shahri",
    name_ru: "город Ташкент",
  },
  {
    name: "Qoraqalpog'iston Respublikasi",
    name_ru: "Республика Каракалпакстан",
  },
  { name: "Andijon viloyati", name_ru: "Андижанская область" },
  { name: "Buxoro viloyati", name_ru: "Бухарская область" },
  { name: "Fargʻona viloyati", name_ru: "Ферганская область" },
  { name: "Jizzax viloyati", name_ru: "Джизакская область" },
  { name: "Namangan viloyati", name_ru: "Наманганская область" },
  { name: "Navoiy viloyati", name_ru: "Навоийская область" },
  { name: "Qashqadaryo viloyati", name_ru: "Кашкадарьинская область" },
  { name: "Samarqand viloyati", name_ru: "Самаркандская область" },
  { name: "Sirdaryo viloyati", name_ru: "Сырдарьинская область" },
  { name: "Surxondaryo viloyati", name_ru: "Сурхандарьинская область" },
  { name: "Toshkent viloyati", name_ru: "Ташкентская область" },
  { name: "Xorazm viloyati", name_ru: "Хорезмская область" },
];
