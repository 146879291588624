import "./layout.style.scss";
import MainLayout from "./MainLayout";

function AppLayout() {
  return (
    <div>
      <MainLayout />
    </div>
  );
}

export default AppLayout;
