import GlobalProvider from "./context/GlobalProvider";
import { BrowserRouter } from "react-router-dom";
import AppLayout from "./layouts/AppLayout";
import CursorProvider from "context/Cursor";
import LocaleProvider from "locale/LocaleProvider";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocaleProvider lang="uz">
        <CursorProvider>
          <BrowserRouter>
            <GlobalProvider>
              <AppLayout />
            </GlobalProvider>
          </BrowserRouter>
        </CursorProvider>
      </LocaleProvider>
    </QueryClientProvider>
  );
}
export default App;
