import Title from "components/Title";
import React, { useRef } from "react";
import useIntersectionObserver from "utils/InterSectionObserver";
import "./title.style.scss";
import { MainPropType } from "types/PropTypes";

function TitleWithP({
  title1,
  title2,
  para,
  style,
  type,
}: {
  title1: string;
  title2: string;
  para: React.ReactNode;
  type?: "small" | "middle" | "large";
} & MainPropType) {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    threshold: 0,
   });

  return (
    <div
      className={`titlewith-p-component ${entry?.isIntersecting ? "active" : ""} ${type}` } 
  
      style={style}
      ref={ref}
    >
      <div>
        <Title type={type}>{title1}</Title>
        <p>{para}</p>
      </div>{" "}
      <Title type={type}>{title2}</Title>
    </div>
  );
}

export default TitleWithP;
