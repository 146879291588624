import About from "components/sections/About";
import Line from "components/Line";
import CustomSlider from "components/sections/Slider";
import StrongPoints from "components/sections/StrongPoints";
import ShowCase from "../../components/sections/ShowCase";
import "./home.style.scss";
import Banner from "components/Banner";
import forum from "assets/images/forum.png";
import ScrollSection from "components/sections/ScrollSection";
import FormConsultation from "components/sections/FormConsultation";
import { useLocaleActions } from "locale/LocaleProvider";

function HomePage() {
  const {getLocaleTranslate} = useLocaleActions()

  return (
    <div>
      <ShowCase />
      <ScrollSection />
      <About style={{ paddingBottom: "calc(40vh + 150px)" }} />
      <Banner src={forum} />
      {/* <FormatCourse style={{ padding: "calc(40vh + 200px) 10% 250px" }} /> */}
      <StrongPoints style={{ margin: "50vh 0 0" }} />
      <CustomSlider type="dark" style={{ padding: "240px 0 90px" }} />
      <FormConsultation
        h1={getLocaleTranslate("freeconsultation.title")}
        p={getLocaleTranslate("freeconsultation.text")}
        orderType="Бесплатную Консультацию"
      />
      <StrongPoints className="footer" />
      <Line />
    </div>
  );
}

export default HomePage;
