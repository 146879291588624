import "./showcase.style.scss";
import { useCursorContext } from "context/Cursor";
import Button from "components/Button";
import { Link } from "react-router-dom";
import useIntersectionObserver from "utils/InterSectionObserver";
import { useRef } from "react";
import CircleAnimation from "components/CircleAnimation";
import { useLocaleActions } from "locale/LocaleProvider";

function ShowCase() {
  const { functions } = useCursorContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const { getLocaleTranslate } = useLocaleActions();
  // const ref = useRef<HTMLDivElement | null>(null);
  return (
    <>
      <div
        className={"showcase " + (entry?.isIntersecting ? "active" : "")}
        ref={ref}
      >
        <CircleAnimation />

        <div className="showcase-content">
          <div className="showcase-text">
            <h1 {...functions}>{getLocaleTranslate("showcase.title1")} </h1>
            <div className="middle-text ">
              <h1 {...functions}>{getLocaleTranslate("showcase.title2")} </h1>
              <p>{getLocaleTranslate("showcase.p")}</p>
            </div>
            <h1 {...functions}>{getLocaleTranslate("showcase.title3")} </h1>
            <Link to="/courses">
              <Button {...{ className: "dark" }}>
                {getLocaleTranslate("showcase.button")}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowCase;
