import AnimateWords from "components/AnimateWords";

import "./points.style.scss";
import { MainPropType } from "types/PropTypes";

function StrongPoints({ className = "", style }: MainPropType) {
  return (
    <div className={"strongpoints-section " + className} style={style}>
      <AnimateWords word=" Strong points - " />
      <AnimateWords word="Strong points - " to="left" />
    </div>
  );
}

export default StrongPoints;
