import React, { useRef } from "react";
import image2 from "assets/images/forum.png";
import "./section.style.scss";
import ImageOval from "components/ImageOval";
import TitleAndText from "components/TitleAndText";
import useIntersectionObserver from "utils/InterSectionObserver";
import { EmitHint } from "typescript";
import { CourseType } from "data/courses";
import { useLocaleActions } from "locale/LocaleProvider";
function ForWhoBody({ course }: { course: CourseType }) {
  const ref = useRef(null);
  const entity = useIntersectionObserver(ref, {});
  const { getQueryLang } = useLocaleActions();
console.log({course});

  return (
    <div
      className={`for-who-body-component ${entity?.isIntersecting && "active"}`}
      ref={ref}
    >
      <div className="image">
        <ImageOval image={`${process.env.REACT_APP_API_URL}static/image/${course.image_name}`} />
      </div>
      <div className="description">
        {course['for_who_rel'].map((forwhotext:any) => (
          <>
            {" "}
            <TitleAndText
              title={getQueryLang(forwhotext.title, forwhotext.title_ru)}
            >
              {getQueryLang(forwhotext.description, forwhotext.description_ru)}
            </TitleAndText>
          </>
        ))}
      </div>
    </div>
  );
}

export default ForWhoBody;
