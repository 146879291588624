import "./form.style.scss";
import React, { useRef, useState } from "react";
import Button from "components/Button";
import useIntersectionObserver from "utils/InterSectionObserver";
import Input from "components/Input";
import Title from "components/Title";
import Spin from "components/Spinner";
import axios from "axios";
import jwtAxios from "auth/jwtAxios";
import SuccessData from "components/SuccessData";
import { CourseType } from "data/courses";
import { useLocaleActions } from "locale/LocaleProvider";
import { sendOrder } from "utils/sendOrder";
import { addresses } from "data/addresses";

function FormConsultation({
  h1,
  p,
  type,
  orderType,
}: {
  h1: string;
  p: string;
  type?: string;
  orderType: string;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { getLocaleTranslate,getQueryLang } = useLocaleActions();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.currentTarget);

    e.currentTarget.reset();

    sendOrder({ message: orderType, ...Object.fromEntries(formData.entries()) })
      .finally(() => {
        // e.target?.reset();
        setLoading(false);
      })
      .then(() => setSuccess(true))
      .catch(console.log);
  };
  return success ? (
    <SuccessData type="dark" />
  ) : (
    <div
      className={`consultant-section  padding-block ${type} ${
        entry?.isIntersecting ? "active" : ""
      }`}
      ref={ref}
    >
      <Title>{h1}</Title>
      <p>{p}</p>{" "}
      <form action="" onSubmit={handleSubmit}>
        <Input
          type="text"
          name="fullName"
          placeholder={getLocaleTranslate("freeconsultation.name")}
          required
        />
        <Input
          type="number"
          placeholder={getLocaleTranslate("freeconsultation.phone")}
          name="phoneNumber"
          required
        />
         <Input
          placeholder={getLocaleTranslate("freeconsultation.place")}
          type="select"
          name="region"
          className="input"
          required
           
        >
          <option value="" style={{color:"black"}}>
            {getLocaleTranslate("freeconsultation.place")}
          </option>
          {addresses.map((region) => 
            <option value={region.name} style={{color:"black"}}>
              {getQueryLang( region.name,region.name_ru  )}
            </option> 
         )}
        </Input>
        <Button {...{ type: "submit", className: type }}>
          {getLocaleTranslate("freeconsultation.button")}
        </Button>
      </form>
      <Spin loading={loading} style={{ position: "absolute", zIndex: 1 }} />
    </div>
  );
}

export default FormConsultation;
