import useIntersectionObserver from "../../utils/InterSectionObserver";
import React, { useRef, useState } from "react";
import ListItems from "./ListItems";
import image1 from "assets/images/unsplash_ZV_64LdGoao.png";

import "./scroll.style.scss";
import ImageOval from "components/ImageOval";
import { useQuery } from "react-query";
import jwtAxios from "auth/jwtAxios";

type PropTypes = {
  style?: React.CSSProperties;
};

function ScrollList({ style }: PropTypes) {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { threshold: 0.3 });

  const [image, setImage] = useState<string>(image1);
  const { data, isLoading, isError } = useQuery(
    ["courses"],
    () => jwtAxios.get(`${process.env.REACT_APP_API_URL}api/course/get-list`),
    {
      onSuccess: (datas) => {
        console.log(datas);
        return datas?.data.map(
          (data: any, index: number) => (data.index = index + 1)
        );
      },
    }
  );

  if (isLoading || isError) {
    return <></>;
  }

  return (
    <div
      className={"scroll-list " + (entry?.isIntersecting ? "active" : "")}
      style={style}
      ref={ref}
    >
      <div className="image">
        <ImageOval
          image={image}
          style={{ display: image ? "block" : "none" }}
        />
      </div>

      <ListItems courses={data?.data} setImage={setImage} />
    </div>
  );
}

export default ScrollList;
