import Title from "components/Title";
import "./course.style.scss";
import CourseNumbers from "components/CourseNumbers";
import { CourseType } from "data/courses";
import { useLocaleActions } from "locale/LocaleProvider";

function CourseStatistics({course}:{course:CourseType}) {  const {   getLocaleTranslate } = useLocaleActions();

  return (
    <div className="coursestatistics-section padding-block">
      <Title style={{ maxWidth: 660 }} from="left">{getLocaleTranslate("course.wait.title")}</Title>
      <CourseNumbers course={course}/>
    </div>
  );
}

export default CourseStatistics;
