import Line from "components/Line";
import "./course.style.scss";
import Title from "components/Title";
import TitleBody from "components/TitleBody";
import { Link } from "react-router-dom";
import { useRef } from "react";
import Button from "components/Button";
import useIntersectionObserver from "utils/InterSectionObserver";
import { CourseType } from "data/courses";
import { useLocaleActions } from "locale/LocaleProvider";

function CourseHead({ course }: { course: CourseType }) {
  const ref = useRef(null);
  const entity = useIntersectionObserver(ref, {});
  const { getQueryLang, getLocaleTranslate } = useLocaleActions();

  return (
    <div
      className={`coursehead-section padding-block ${
        entity?.isIntersecting && "active"
      }`}
      ref={ref}
    >
      <Title >{getQueryLang(course.title, course.title_ru)}</Title>
      <Line className="padding-line" />
      <TitleBody
        title={getLocaleTranslate("course.body.title")}
        texts={[getQueryLang(course.description, course.description_ru )]}
      /> 
      <Link
        to=""
        onClick={() =>
          document
            .getElementById("order")
            ?.scrollIntoView({ behavior: "smooth" })
        }
      >
        <Button>{getLocaleTranslate("course.button")}</Button>
      </Link>
    </div>
  );
}

export default CourseHead;
