import { Link, Navigate } from "react-router-dom";
import AboutPage from "./AboutPage";
import ContactPage from "./ContactPage";
import HomePage from "./HomePage";
import NewsPage from "./NewsPage";
import NotFound from "./NotFound";
import SingleNews from "./SingleNews";
import CoursePage from "./CoursePage";
import CoursesPage from "./CoursesPage";
import { AiFillPhone, AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { RiTelegramLine } from "react-icons/ri";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { IoLogoTiktok } from "react-icons/io5";

export type RouteType = {
  path: string;
  page: JSX.Element;
  id: string;
};

const routes: RouteType[] = [
  { id: "1", path: "/home", page: <HomePage /> },
  { id: "2", path: "/about", page: <AboutPage /> },
  { id: "3", path: "/contact", page: <ContactPage /> },

  {
    id: "4",
    path: "/news",
    page: (
      <NotFound
        text={
          <>
            В настоящее время новости о Mr.IT, бесплатные открытые уроки и более
            подробная информация о новых курсах публикуются в наших социальных
            сетях ниже. Следите за нами в социальных сетях, чтобы быть в курсе
            предстоящих новостей.
            <div
              style={{
                alignItems: "center",
                display: "flex",
                gap: "1vw",
                paddingBlock: 30,
              }}
            >
              <Link to="https://www.instagram.com/mr_it/" target="_blank">
                <AiOutlineInstagram size={30} />
              </Link>
              <Link to="https://t.me/mr_it_uz" target="_blank">
                <RiTelegramLine size={30} />
              </Link>
              <Link
                to="https://www.facebook.com/profile.php?id=100090913510545"
                target="_blank"
              >
                <TiSocialFacebookCircular size={30} />{" "}
              </Link>
              <Link
                to="https://www.tiktok.com/@upteaapie0f?_r=1&_d=e60j77hc5cld6m&language=en&sec_uid=MS4wLjABAAAAlwMAuiB5meF-Ir14V_aSk9ksj_qC3k3TE2pNSXhniuxJ7sNXfSN8P1iABZoRsrN7&share_author_id=7214041436219425793&source=h5_m&u_code=dgmi7hlj53dj2l&timestamp=1680603913&user_id=6924828201664939014&sec_user_id=MS4wLjABAAAAExZC3qoEjIDkHRM17dsuRUoFQg7F8ykOR2Y0n4S6pNBR2WFnOctXYVsjrpf4B6qe&utm_source=telegram&utm_campaign=client_share&utm_medium=android&share_iid=7217638088586643201&share_link_id=5da424b4-20d5-435b-aa1b-0a658bc3b300&share_app_id=1233&ugbiz_name=Account&ug_btm=b6880%2Cb5836&social_share_type=5"
                target="_blank"
              >
                <IoLogoTiktok size={25} />{" "}
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCF3Fco5fpdooBEG78ov1q9g"
                target="_blank"
              >
                <AiFillYoutube size={30} />{" "}
              </Link>
            </div>
            Если у Вас возникли вопросы, Вы можете позвонить по этим телефонным
            номерам:
            <div style={{display:"flex",alignItems:"center",paddingTop:20,gap:"1vw"}}>
            <Link to="tel:+ 998 93 376 92 29 " style={{display:"flex",alignItems:"center"}}>
                <AiFillPhone
                  size={30}
                  style={{ transform: "rotateY(180deg)" }}
                />
                + 998 (93) 376-92-29
              </Link> <Link to="tel:+ 998 99 372 09 69 " style={{display:"flex",alignItems:"center"}}>
                <AiFillPhone
                  size={30}
                  style={{ transform: "rotateY(180deg)" }}
                />
+ 998 (99) 372-09-69              </Link>
            </div>
          </>
        }
      />
    ),
  },
  { id: "5", path: "/news/:id", page: <SingleNews /> },

  { id: "5", path: "/courses", page: <CoursesPage /> },
  { id: "6", path: "/courses/:id", page: <CoursePage /> },

  { id: "6", path: "/not-found", page: <NotFound /> },
  { id: "0", path: "/", page: <Navigate to="/home" replace={true} /> },
  { id: "-1", path: "*", page: <Navigate to="/not-found" replace={true} /> },
];

export default routes;
