import TitleWithP from "components/TitleWithP";
import "./section.style.scss";
import ForWhoBody from "components/ForWhoBody";
import { CourseType } from "data/courses";
import { useLocaleActions } from "locale/LocaleProvider";

function ForWhoSection({ course }: { course: CourseType }) {
  const { getQueryLang, getLocaleTranslate } = useLocaleActions();

  return (
    <div className="for-who-section padding-block">
      <TitleWithP
        title1={getLocaleTranslate("course.forwho.title1")}
        title2={getLocaleTranslate("course.forwho.title2")}
        para={getQueryLang(course.sub_title,course.sub_title_ru)}
        style={{ padding: "0 10% 10%  " }}
        type="small"
      />
      <ForWhoBody course={course} />
    </div>
  );
}

export default ForWhoSection;
