import { MainPropType } from "types/PropTypes";
import "./spin.style.scss";

function Spin({ loading ,style}: { loading: boolean } & MainPropType) {
  return (
    <div className="spinner" style={{ display:  loading ?"flex": "none" ,...style}}>
      <div className="blobs">
        <div className="blob-center"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
      </div>
    </div>
  );
}

export default Spin;
