import CursorProvider from "context/Cursor";
import { useGlobalContext } from "context/GlobalProvider";
import Navigator from "layouts/Navigator";
import { useEffect, useState } from "react";
import Footer from "../Footer";
import RouteGenerator from "./RouteGenerator";
import Spin from "components/Spinner";
import { useLocation } from "react-router-dom";

function MainLayout() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { loading } = useGlobalContext();
  const { pathname } = useLocation();
  useEffect(() => {
    const x = window.scrollX;
    const y = window.scrollY;
    window.onscroll = isActive
      ? function () {
          window.scrollTo(x, y);
        }
      : () => {};
  }, [isActive]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const noFooter:string[] = [ "/news", "/not-found"]

  return (
    <CursorProvider>
      <Spin loading={loading} />
      <div
        className="app-layout"
        style={loading ? { height: "100vh", overflow: "hidden" } : {}}
      >
        <Navigator {...{ isActive, setIsActive }} />
        <RouteGenerator />
        {  !noFooter.includes(pathname) ? <Footer /> : ""}
      </div>
    </CursorProvider>
  );
}

export default MainLayout;
