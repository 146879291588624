import "./form.style.scss";

import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { Link } from "react-router-dom";
import { RiTelegramLine } from "react-icons/ri";
import { useRef } from "react";
import useIntersectionObserver from "utils/InterSectionObserver";
import { useLocaleActions } from "locale/LocaleProvider";

export type ContactType = {
  type: string;
  link: string;
  title: string;
};

export const contacts: ContactType[] = [
  {
    type: "phone",
    link: "tel:+ 998(93) 376-92-29 ",
    title: "+998(93) 376-92-29 ",
  },
  {
    type: "phone",
    link: "tel:+998(99) 372-09-69",
    title: "+998(99) 372-09-69",
  },
  {
    type: "mail",
    link: "mailto:upteamall@gmail.com",
    title: "upteamall@gmail.com",
  },
  {
    type: "instagram",
    link: "https://www.instagram.com/mr_it/",
    title: "mr_it",
  },
  {
    type: "telegram",
    link: "https://t.me/mr_it_uz",
    title: "Mr_IT",
  },
  {
    type: "facebook",
    link: "https://www.facebook.com/profile.php?id=100090913510545",
    title: "Mister_itschool",
  },
];

function Contact({ type }: { type?: string }) {
  const ref = useRef<HTMLDivElement>(null);
  const entity = useIntersectionObserver(ref, {});
  const { getLocaleTranslate } = useLocaleActions();

  return (
    <div className={`contact-section ${entity?.isIntersecting && "active"}`}>
      <p>{getLocaleTranslate("freeconsultation.text")}</p>
      <div className="items">
        {contacts.map((contact: ContactType, i) => {
          return (
            <Link to={contact.link} target={"_blank"} key={i}>
              {getIcon(contact.type)}
              {contact.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

function getIcon(contactType: string) {
  let icon;
  switch (contactType) {
    case "phone":
      icon = <FiPhoneCall style={{ minWidth: 24 }} />;
      break;
    case "mail":
      icon = <AiOutlineMail style={{ minWidth: 24 }} />;
      break;
    case "instagram":
      icon = <AiOutlineInstagram style={{ minWidth: 24 }} />;
      break;
    case "facebook":
      icon = <TiSocialFacebookCircular style={{ minWidth: 24 }} />;
      break;
    case "telegram":
      icon = <RiTelegramLine style={{ minWidth: 24 }} />;
      break;

    default:
      break;
  }

  return icon;
}

export default Contact;
