import Title from "components/Title";
import React from "react";
import Slider from "react-slick";
import { MainPropType } from "types/PropTypes";
import "./slider.style.scss";
import SliderInfoSlider from "components/SliderInfoSlider";

function SliderInfos({
  title,
  style,
  children,
}: { title: string; children: React.ReactNode[]  } & MainPropType) {
  return (
    <div style={style} className="slider-infos-section padding-block">
      <Title
        type="small"
        style={{
          margin: "0 10% calc(5vw + 20px)",
          maxWidth: "800px",
        }}
        from="left"
      >
        {title}
      </Title>

      <SliderInfoSlider>{children}</SliderInfoSlider>
    </div>
  );
}

export default SliderInfos;
