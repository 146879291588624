import "./hamburger.style.scss";

function Hamburger({ size }: { size: number }) {
  return (
    <div
      className="hamburger"
      style={{ width: size, height: size / 1.2 }}
    ></div>
  );
}

export default Hamburger;
