import Button from "components/Button";
import { useCursorContext } from "context/Cursor";
import useIntersectionObserver from "utils/InterSectionObserver";
import Line from "components/Line";
import { Link } from "react-router-dom";
import fold from "assets/images/fold.png";
import React, { useRef } from "react";
import "./about.style.scss";
import { MainPropType } from "types/PropTypes";
import TitleBody from "components/TitleBody";
import Title from "components/Title";
import { useLocaleActions } from "locale/LocaleProvider";

function About({ style, type }: MainPropType & { type?: "dark" }) {
  const { functions } = useCursorContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const { getLocaleTranslate } = useLocaleActions();

  return (
    <>
      <div
        className={`about-section ${
          entry?.isIntersecting ? "active" : ""
        } ${type}`}
        style={style}
        ref={ref}
      >
        <div className="title">
          <Title type="large" from="left">
            {getLocaleTranslate("about.title")}
          </Title>
          <div>
            <div className="course">
              <p>PYTHON</p>
              <p>SCRATCH</p>
              <p>FRONTEND</p>
              <p>IN/EX</p>
            </div>
            <h2 {...functions}>+10</h2>
            <p>{getLocaleTranslate("about.title.p")}</p>
          </div>
        </div>
        <Line className="darker padding-line" />
        <TitleBody
          title={getLocaleTranslate("about.body.title")}
          texts={[
            getLocaleTranslate("about.body.text1"),
            getLocaleTranslate("about.body.text2"),
          ]}
        />
        {type === "dark" || (
          <Link to={"/about"}>
            <Button {...{ className: type }}>
              {getLocaleTranslate("about.button")}
            </Button>
          </Link>
        )}
      </div>
    </>
  );
}

export default React.memo(About);
