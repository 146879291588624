import React, { useLayoutEffect, useRef } from "react";
import styles from "./image.module.scss";
import { useCursorContext } from "context/Cursor";
import { MainPropType } from "types/PropTypes";

function ImageOval({ image,style  }: { image: string } &MainPropType) {
  const imgRef = useRef<HTMLDivElement | null>(null);
  const { functions } = useCursorContext();

  useLayoutEffect(() => {
    if (!imgRef.current) return;

    imgRef.current.classList.remove(styles.animate);
    setTimeout(() => {
      imgRef.current?.classList.add(styles.animate);
    }, 0);
  }, [image]);

  return (
    <div className={styles.image} ref={imgRef} {...functions} style={style}>
      <img src={image} alt="" />
    </div>
  );
}

export default ImageOval;
