 import React from "react";
import "./line.style.scss";

function Line({ className, ...rest }: { className?: string }) {
 
  return (
    <div
      className={`line-component ${className} `  }
      {...rest}
     ></div>
  );
}

export default Line;
