import Contact from "components/sections/Contact";
import React from "react";
import "./contact.style.scss";
import FormConsultation from "components/sections/FormConsultation";
import { useLocaleActions } from "locale/LocaleProvider";

function ContactPage() {
  const { getLocaleTranslate } = useLocaleActions();

  return (
    <div>
      <FormConsultation
        type="dark"
        h1={getLocaleTranslate("freeconsultation.title")}
        p={getLocaleTranslate("freeconsultation.text")}
        orderType="Бесплатную Консультацию"
      />
      <Contact />
    </div>
  );
}

export default ContactPage;
