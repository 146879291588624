import Line from "components/Line";
import About from "components/sections/About";
import FormConsultation from "components/sections/FormConsultation";
import Numbers from "components/sections/Numbers";
import CustomSlider from "components/sections/Slider";
import "./about.style.scss";
import { useLocaleActions } from "locale/LocaleProvider";

function AboutPage() {  const {getLocaleTranslate} = useLocaleActions()

  return (
    <div>
      <About type="dark" />
      <Line />
      {/* <Numbers /> */}
      <CustomSlider style={{ padding: "90px 0 " }} />
      <FormConsultation
        type="dark"
        h1={getLocaleTranslate("freeconsultation.title")}
        p={getLocaleTranslate("freeconsultation.text")}
        orderType="Бесплатную Консультацию"
      />
    </div>
  );
}

export default AboutPage;
