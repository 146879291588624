import "./button.style.scss";
import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { useCursorContext } from "../../context/Cursor";
import { MainPropType } from "types/PropTypes";

function Button({
  children,style,
   ...rest
}:MainPropType) {
  const { functions } = useCursorContext();
  return (
    <button style={style} {...rest} id="button" {...functions}>
      {children}
      <FiArrowUpRight className="icon" />
    </button>
  );
}

export default Button;
