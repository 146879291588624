import ScrollList from "components/ScrollList";
import React from "react";

function CoursesPage() {
  return (
    <div>
      <ScrollList style={{ marginBottom: "100px" }}></ScrollList>
    </div>
  );
}

export default CoursesPage;
