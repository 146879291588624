import styles from "./input.module.scss";

function Input({ ...rest }) {
  return rest.type !== "select" ? (
    <input {...rest} className={`${styles.input} ${rest.className}`} />
  ) : (
    <select {...rest} className={`${styles.input} ${rest.className}`} />
  );
}

export default Input;
