import "./circle.style.scss";
import noise from "assets/images/Noise.png";
import smallcircle from "assets/images/Frame 355.png";
import plastic from "assets/images/plastic.png";
import bigcircle from "assets/images/Frame 356.png";
import { onload } from "utils/functions/onload";

function CircleAnimation() {
  return (
    <div className="circle-animation-comp">
      <img
        src={noise}
        alt=""
        onLoad={onload}
        className="image showcase-noise lazy-load"
      />
      <img
        src={smallcircle}
        alt=""
        onLoad={onload}
        className="image circle small-circle lazy-load"
      />
      <img
        src={bigcircle}
        alt=""
        onLoad={onload}
        className="image circle big-circle lazy-load"
      />
      <img
        src={plastic}
        alt=""
        onLoad={onload}
        className="image plastic-image lazy-load"
      />
      <img
        src={plastic}
        alt=""
        onLoad={onload}
        className="image plastic-image lazy-load"
      />
    </div>
  );
}

export default CircleAnimation;
