import "./logo.style.scss";
import logo from "assets/images/logo.svg";
import { useCursorContext } from "context/Cursor";

function Logo() {
  const { functions } = useCursorContext();
  return <img className="logo-comp" {...functions} src={logo} alt="" />;
}

export default Logo;
