import { MainPropType } from "types/PropTypes";
import "./styles.style.scss";
import { useCursorContext } from "context/Cursor";
import { useRef } from "react";
import useIntersectionObserver from "utils/InterSectionObserver";

function TitleAndText({
  title,
  children,
  style,
}: { title: string } & MainPropType) {
  const {functions} = useCursorContext()

  const ref = useRef(null)
  const entity = useIntersectionObserver(ref,{})

  return (
    <div className={`text-and-title-component ${entity?.isIntersecting && "active"}`}  style={style} ref={ref}>
      <h1 {...functions}>{title}</h1>
      <p>{children}</p>{" "}
    </div>
  );
}

export default TitleAndText;
