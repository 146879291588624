import image from  "assets/images/unsplash_ZV_64LdGoao.png"
import profile from  "assets/images/Ellipse 1581.png"

export type NewsType =  {
    id: string;
    image: string;
    title: string;
    categories: string[];
    time: string;
    user: {
        image: string;
        name: string;
    };
};


export const newsList = [
    {
      id: "1",
      image ,
      title: "New pricing for GitLab Premium Ease of getting started",
      categories: ["Gitlab", "Price", "Code", "Premium"],
      time:"10 min",
      user: {
        image: profile,
        name: "`MIT Academy`",
      },
    },
    {
      id: "2",
      image ,
      title: "New pricing for GitLab Premium Ease of getting started",
      categories: ["Gitlab", "Price", "Code", "Premium"],
      time:"10 min",
      user: {
        image: profile,
        name: "`MIT Academy`",
      },
    },
    {
      id: "3",
      image ,
      title: "New pricing for GitLab Premium Ease of getting started",
      categories: ["Gitlab", "Price", "Code", "Premium"],
      time:"10 min",
      user: {
        image: profile,
        name: "`MIT Academy`",
      },
    },
    {
      id: "4",
      image ,
      title: "New pricing for GitLab Premium Ease of getting started",
      categories: ["Gitlab", "Price", "Code", "Premium"],
      time:"10 min",
      user: {
        image: profile,
        name: "`MIT Academy`",
      },
    },
    {
      id: "5",
      image ,
      title: "New pricing for GitLab Premium Ease of getting started",
      categories: ["Gitlab", "Price", "Code", "Premium"],
      time:"10 min",
      user: {
        image: profile,
        name: "`MIT Academy`",
      },
    },
    {
      id: "6",
      image ,
      title: "New pricing for GitLab Premium Ease of getting started",
      categories: ["Gitlab", "Price", "Code", "Premium"],
      time:"10 min",
      user: {
        image: profile,
        name: "`MIT Academy`",
      },
    },
    {
      id: "7",
      image ,
      title: "New pricing for GitLab Premium Ease of getting started",
      categories: ["Gitlab", "Price", "Code", "Premium"],
      time:"10 min",
      user: {
        image: profile,
        name: "`MIT Academy`",
      },
    },
    {
      id: "8",
      image ,
      title: "New pricing for GitLab Premium Ease of getting started",
      categories: ["Gitlab", "Price", "Code", "Premium"],
      time:"10 min",
      user: {
        image: profile,
        name: "`MIT Academy`",
      },
    },
  {
    id: "9",
    image ,
    title: "New pricing for GitLab Premium Ease of getting started",
    categories: ["Gitlab", "Price", "Code", "Premium"],
    time:"10 min",
    user: {
      image: profile,
      name: "`MIT Academy`",
    },
  },
  {
    id: "10",
    image ,
    title: "New pricing for GitLab Premium Ease of getting started",
    categories: ["Gitlab", "Price", "Code", "Premium"],
    time:"10 min",
    user: {
      image: profile,
      name: "`MIT Academy`",
    },
  },
  {
    id: "11",
    image ,
    title: "New pricing for GitLab Premium Ease of getting started",
    categories: ["Gitlab", "Price", "Code", "Premium"],
    time:"10 min",
    user: {
      image: profile,
      name: "`MIT Academy`",
    },
  },
  {
    id: "12",
    image ,
    title: "New pricing for GitLab Premium Ease of getting started",
    categories: ["Gitlab", "Price", "Code", "Premium"],
    time:"10 min",
    user: {
      image: profile,
      name: "`MIT Academy`",
    },
  },
  {
    id: "13",
    image ,
    title: "New pricing for GitLab Premium Ease of getting started",
    categories: ["Gitlab", "Price", "Code", "Premium"],
    time:"10 min",
    user: {
      image: profile,
      name: "`MIT Academy`",
    },
  },
  {
    id: "14",
    image ,
    title: "New pricing for GitLab Premium Ease of getting started",
    categories: ["Gitlab", "Price", "Code", "Premium"],
    time:"10 min",
    user: {
      image: profile,
      name: "`MIT Academy`",
    },
  },
  {
    id: "15",
    image ,
    title: "New pricing for GitLab Premium Ease of getting started",
    categories: ["Gitlab", "Price", "Code", "Premium"],
    time:"10 min",
    user: {
      image: profile,
      name: "`MIT Academy`",
    },
  },
];
