import React from "react";
import { Routes, Route } from "react-router-dom";
import routes, { RouteType } from "../../pages/routeConfig";

function RouteGenerator() {
  return <Routes>{getRoutes()}</Routes>;
}

function getRoutes() {
  return routes.map((route: RouteType) => (
      <Route key={route.id} path={route.path} element={route.page} />
  ));
}

export default RouteGenerator;
