import { useCursorContext } from "context/Cursor";
import Line from "components/Line";
import React from "react";
import { Link, useLoaderData } from "react-router-dom";
 import { CourseType } from "data/courses";
import { useLocaleActions } from "locale/LocaleProvider";

function Item({
course,
  setImage,
}:   { course:CourseType, setImage: any }) {
  const { functions } = useCursorContext();
const {getQueryLang} = useLocaleActions()
  return (
    <>
      <div className="list-item " onClick={() => setImage(`${process.env.REACT_APP_API_URL}static/image/${course.image_name}`)}>
        <div className="item-content">
          <h3 {...functions}>/{(`${course.index}`).padStart(2,"0")}</h3>
          <div className="right-content">
            <Link to={`/courses/${course.id}`}>
              <h1 {...functions}>{getQueryLang(course.title,course.title_ru) }</h1>
            </Link>
            <div className="needs">
              {/* {needs.map((need, i) => (
                <p key={i}>{need}</p>
              ))} */}
              <p>
                {getQueryLang(course.type,course.type_ru)   }
              </p>
            </div>
          </div>
        </div>
        <Line />
      </div>
    </>
  );
}

export default Item;
