import Title from "components/Title";
import "./seccess.style.scss";
import { Link } from "react-router-dom";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { RiTelegramLine } from "react-icons/ri";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { FiPhoneCall } from "react-icons/fi";
import { useLocaleActions } from "locale/LocaleProvider";
import { IoLogoTiktok } from "react-icons/io5";

function SuccessData({ type }: { type?: string }) {
  const { getLocaleTranslate } = useLocaleActions();

  return (
    <div className={`success-data-component padding-block ${type}`}>
      <Title>{getLocaleTranslate("succes.title")}</Title>{" "}
      <p>{getLocaleTranslate("succes.text")} </p>
      <h2 className="title-font">{getLocaleTranslate("succes.meneger")}</h2>
      <Link to="tel:+998993720969">
        <FiPhoneCall />
        +998 (99) 372-09-69
      </Link>
      <div className="socials">
        <Link to="https://www.instagram.com/mr_it/" target="_blank">
          <AiOutlineInstagram size={30} />
        </Link>
        <Link to="https://t.me/mr_it_uz" target="_blank">
          <RiTelegramLine size={30} />
        </Link>
        <Link
          to="https://www.facebook.com/profile.php?id=100090913510545"
          target="_blank"
        >
          <TiSocialFacebookCircular size={30} />{" "}
        </Link>
        <Link
          to="https://www.tiktok.com/@upteaapie0f?_r=1&_d=e60j77hc5cld6m&language=en&sec_uid=MS4wLjABAAAAlwMAuiB5meF-Ir14V_aSk9ksj_qC3k3TE2pNSXhniuxJ7sNXfSN8P1iABZoRsrN7&share_author_id=7214041436219425793&source=h5_m&u_code=dgmi7hlj53dj2l&timestamp=1680603913&user_id=6924828201664939014&sec_user_id=MS4wLjABAAAAExZC3qoEjIDkHRM17dsuRUoFQg7F8ykOR2Y0n4S6pNBR2WFnOctXYVsjrpf4B6qe&utm_source=telegram&utm_campaign=client_share&utm_medium=android&share_iid=7217638088586643201&share_link_id=5da424b4-20d5-435b-aa1b-0a658bc3b300&share_app_id=1233&ugbiz_name=Account&ug_btm=b6880%2Cb5836&social_share_type=5"
          target="_blank"
        >
          <IoLogoTiktok size={25} />{" "}
        </Link>
        <Link
          to="https://www.youtube.com/channel/UCF3Fco5fpdooBEG78ov1q9g"
          target="_blank"
        >
          <AiFillYoutube size={30} />{" "}
        </Link>
      </div>
    </div>
  );
}

export default SuccessData;
