import { FormatLessonType } from "data";
import { useRef } from "react";
import "./lesson.style.scss";
import useIntersectionObserver from "utils/InterSectionObserver";
import { useLocaleActions } from "locale/LocaleProvider";

function FormatLessonCard({
  formatLesson,
}: {
  formatLesson: FormatLessonType;
}) {
  const ref = useRef(null)
  const entity = useIntersectionObserver(ref,{})
  const { getQueryLang } = useLocaleActions();

  return (
    <div className={`format-lesson-card-component ${entity?.isIntersecting && "active"}`} ref={ref}>
      <h1 className="title-font">{getQueryLang(formatLesson.group,formatLesson.group_ru)}</h1>
      <p>{getQueryLang(formatLesson.desc,formatLesson.desc_ru)}</p>
      <p>
        {
          getQueryLang(formatLesson.desc_2,formatLesson.desc_2_ru)
        }
      </p>
      <h2 className="title-font">{formatLesson.price}</h2>
    </div>
  );
}

export default FormatLessonCard;
