import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./slider.style.scss";
import { AiOutlineInstagram } from "react-icons/ai";
import profile from "assets/images/profile.png";
import { useCursorContext } from "context/Cursor";
import useIntersectionObserver from "utils/InterSectionObserver";
import { useRef } from "react";
import { MainPropType } from "types/PropTypes";
import Profile from "components/Profile";
import Title from "components/Title";
import TitleWithP from "components/TitleWithP";
import { useLocaleActions } from "locale/LocaleProvider";
import { TeacherType, teachers } from "data/teachers";
import Spin from "components/Spinner";
import { useQuery } from "react-query";
import jwtAxios from "auth/jwtAxios";

type DataType = {
  id: string;
  social: {
    icon: JSX.Element;
    link: string;
  };
  image: string;
  job: string;
  does: string;
  name: string;
};

type PropType = {
  className?: string;
  onClick?: () => void;
};
function SampleNextArrow(props: PropType) {
  const { functions } = useCursorContext();

  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} {...functions}>
      <div> </div>
    </div>
  );
}

function SamplePrevArrow(props: PropType) {
  const { functions } = useCursorContext();

  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} {...functions}>
      <div>w</div>
    </div>
  );
}
const settings = {
  className: "main-slider",
  centerMode: true,
  infinite: true,
  centerPadding: "30%",
  slidesToShow: 1,
  speed: 1000,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  autoplay: true,
  autoplaySpeed: 5000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 1,
        centerPadding: "30%",
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 1,
        centerPadding: "27%",
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 1,
        centerPadding: "23%",
      },
    },
    {
      breakpoint: 1150,
      settings: {
        slidesToShow: 1,
        centerPadding: "18%",
      },
    },
    {
      breakpoint: 870,
      settings: {
        slidesToShow: 1,
        speed: 500,
        centerPadding: "11%",
      },
    },
  ],
};

function CustomSlider({ type, style }: { type?: "dark" } & MainPropType) {
  const { functions } = useCursorContext();
  const { getLocaleTranslate, getQueryLang } = useLocaleActions();
  const { data, isLoading } = useQuery(["teachers"], () =>
    jwtAxios.get(`${process.env.REACT_APP_API_URL}api/teacher/get-list`)
  );
 
  return (
    <div className={"custom-slider " + type} style={style}>
      <TitleWithP
        title1={getLocaleTranslate("teachers.title1")}
        title2={getLocaleTranslate("teachers.title2")}
        para={
          <p style={{ maxWidth: 200 }}>{getLocaleTranslate("teachers.text")}</p>
        }
        style={{ margin: " 0 10% 100px" }}
      />

      <div className="custom-slider-container">
        <Spin loading={isLoading} style={{ position: "absolute", zIndex: 1 }} />
        <Slider {...settings}>
          {data?.data?.map((data: any, i: number) => (
            <div key={i}>
              {data.image_name}
              <div className="slide-container">
                <Profile
                  src={`${process.env.REACT_APP_API_URL}static/image/${data.image}`}
                />
                <div className="text">
                  <h2 {...functions}>
                    {getQueryLang(data.name, data.name_ru)}
                  </h2>
                  <p className="job">
 
                    {getQueryLang(data.course, data.course_ru)}
                  </p>
                  <p className="does">
 
                    {getQueryLang(data.description, data.description_ru)}
                    </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default CustomSlider;
