import jwtAxios from "auth/jwtAxios";
import courses, { CourseType } from "data/courses";
import { useLocaleActions } from "locale/LocaleProvider";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

function SmallMenu() {
  const { getQueryLang } = useLocaleActions();
  const {data:courses} = useQuery(["courses"] , ()=> jwtAxios.get(`${process.env.REACT_APP_API_URL}api/course/get-list`) )
console.log(courses?.data);

  return (
    <>
      {courses?.data.map((course: CourseType) => (
        <Link to={`/courses/${course.id}`} key={course.id}>
          {getQueryLang(course.title, course.title)}
        </Link>
      ))}
    </>
  );
}

export type MenuPageType = {
  name: string;
  link: string;
  hover?: JSX.Element;
  id: string;
};
export const menuPages: MenuPageType[] = [
  {
    name: "menu.link.courses",
    link: "/courses",
    hover: <SmallMenu />,
    id: "1",
  },
  {
    name: "menu.link.about",
    link: "/about",
    id: "2",
  },
  // {
  //   name: "Новости",
  //   link: "/news",
  //   id: "3",
  // },
  {
    name: "menu.link.contact",
    link: "/contact",
    id: "4",
  },
];
