import Button from "../Button";
import { useCursorContext } from "../../context/Cursor";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { RiTelegramLine } from "react-icons/ri";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { Link } from "react-router-dom";
import { MenuPageType, menuPages } from "pages/menuConfig";
import { FaTiktok } from "react-icons/fa";
import { IoLogoTiktok } from "react-icons/io5";
import { useLocaleActions } from "locale/LocaleProvider";
import "./menu.style.scss";

function Menu({ isActive, ...rest }: { isActive: boolean }) {
  const { functions } = useCursorContext();
  const { getLocaleTranslate } = useLocaleActions();

  return (
    <>
      <div className={"menu-background " + (isActive ? "active" : "")}></div>

      <div className={"menu " + (isActive ? "active" : "")} {...rest}>
        {" "}
        <div className="socials">
          <div className="email">
            <h3 {...functions}>{getLocaleTranslate("menu.email")}</h3>
            <Link to="mailto:upteamall@gmail.com">upteamall@gmail.com</Link>
          </div>
          <div className="email">
            <h3 {...functions}>{getLocaleTranslate("menu.phone")}</h3>
            <Link to="tel:+998 (55)515-30-30">+998 (55)515-30-30</Link>
          </div>
          <div
            className="icons"
            style={{
              alignItems: "center",
            }}
          >
             <Link to="https://www.instagram.com/mitacademyuz?igsh=MTFjNmdtYzQ5djU2eA==" target="_blank">
            <AiOutlineInstagram size={30} />
          </Link>
          <Link to="https://t.me/mitacademyuz" target="_blank">
            <RiTelegramLine size={30} />
          </Link>
          <Link
            to="https://www.facebook.com/profile.php?id=61550105426997&mibextid=JRoKGi"
            target="_blank"
          >
            <TiSocialFacebookCircular size={30} />{" "}
          </Link>
          </div>
          <div className="whatelse">
            <h3> {getLocaleTranslate("menu.question")}</h3>
            <Link to="/contact">
              <Button {...{ className: "green" }}>
                {getLocaleTranslate("menu.button")}
              </Button>
            </Link>
          </div>
        </div>
        <div className="center"> </div>
        <div className="pages">
          {menuPages.map((page: MenuPageType) => (
            <div key={page.id}>
              <div className="center">{page.hover}</div>
              <Link to={page.link} {...functions}>
                <h1> {getLocaleTranslate(page.name)}</h1>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Menu;
