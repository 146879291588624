import { useState, createContext, useContext } from "react";
import uzLangs from "./uz.json";
import ruLangs from "./ru.json";
import { MainPropType } from "types/PropTypes";

export type LangType = "uz" | "ru";

const Context = createContext<undefined | any>(undefined);

export const useLocaleActions = () => {
  return useContext(Context);
};

function LocaleProvider({ children, lang }: { lang: LangType } & MainPropType) {
  const [locale, setLocale] = useState<{ lang: LangType; langsObject: any }>({
    lang: lang,
    langsObject: uzLangs,
  });

  const setLocaleLanguage = (lang: LangType) => {
    switch (lang) {
      case "uz":
        setLocale((prev) => ({ ...prev, lang, langsObject: uzLangs }));
        break;
      case "ru":
        setLocale((prev) => ({ ...prev, lang, langsObject: ruLangs }));
        break;

      default:
        break;
    }
  };
  const getLocaleTranslate = (id: string) => {
    return locale.langsObject[id];
  };
  const getQueryLang = (uz:string,ru:string) => {
    return locale.lang === "uz" ? uz : ru
  };
  return (
    <Context.Provider value={{ setLocaleLanguage,  getLocaleTranslate,getQueryLang ,lang:locale.lang}}>
      {children}
    </Context.Provider>
  );
}

export default LocaleProvider;
