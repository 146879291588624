import { LessonType, ruMonths } from "data";
import { useRef } from "react";
import "./lesson.style.scss";
import useIntersectionObserver from "utils/InterSectionObserver";
import { useLocaleActions } from "locale/LocaleProvider";

function LessonCard({ lesson }: { lesson: any }) {
  const ref = useRef(null);
  const entity = useIntersectionObserver(ref, {});
  const { getQueryLang } = useLocaleActions();

  return (
    <div
      className={`lesson-card-component ${entity?.isIntersecting && "active"}`}
      ref={ref}
    >
      <h1 className="title-font">{getQueryLang(lesson.when_start, lesson.when_start_ru)  }</h1>

      <div className="lang">
        <h2>{getQueryLang(lesson.group_lang, lesson.group_lang_ru)}</h2>
        <p>{getQueryLang(lesson.time_start, lesson.time_start_ru)}</p>
        <p>{getQueryLang(lesson.time_end, lesson.time_end_ru)}</p>
        <p>{getQueryLang(lesson.weeks, lesson.weeks_ru)}</p>
      </div>
    </div>
  );
}

export default LessonCard;
