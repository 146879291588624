import ScrollList from "components/ScrollList";
import Title from "components/Title";
import { MainPropType } from "types/PropTypes";
import "./scroll.style.scss";
import { useRef } from "react";
import useIntersectionObserver from "utils/InterSectionObserver";
import { useLocaleActions } from "locale/LocaleProvider";

function ScrollSection({ style }: MainPropType) {
  const ref = useRef<HTMLDivElement>(null);
  const entity = useIntersectionObserver(ref, {});
  const { getLocaleTranslate } = useLocaleActions();
  return (
    <div className="scroll-section" style={style}>
      <div className={`title ${entity?.isIntersecting && "active"}`} ref={ref}>
        <Title>{getLocaleTranslate("courses.title")}</Title>
        <p>{getLocaleTranslate("courses.p")} </p>
      </div>

      <ScrollList />
    </div>
  );
}

export default ScrollSection;
