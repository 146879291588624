import "./slider.style.scss";
import Slider from "react-slick";

function SliderInfoSlider({ children }: { children: React.ReactNode[] }) {

   const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10%",
    slidesToShow: Math.min(children.length, 3),
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: "linear",
    pauseOnHover: true,      swipeToSlide: true,

    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: Math.min(children.length, 2),
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-info-component">   
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}

export default SliderInfoSlider;
