import React, { createContext, useContext, useEffect, useState } from "react";

export type ContextType = {
  loading: boolean;
};
export const GlobalContext = createContext<ContextType>({ loading: true });
export const GlobalContextAction = createContext<{
  setContext?: React.Dispatch<React.SetStateAction<ContextType>>;
}>({});

export const useGlobalContext = () => useContext(GlobalContext);
export const useGlobalContextActions = () => useContext(GlobalContextAction);

function GlobalProvider({ children }: { children: React.ReactNode }) {
  const [context, setContext] = useState<ContextType>({ loading: true });
  const handleDOMContentLoaded = () => {
    setContext((context) => {
      return { ...context, loading: false };
    });
  };
  useEffect(() => {
    window.addEventListener("load", handleDOMContentLoaded);

       setTimeout(handleDOMContentLoaded, 3000);
   }, []);

  return (
    <GlobalContext.Provider value={{ ...context }}>
      <GlobalContextAction.Provider value={{ setContext }}>
        {children}
      </GlobalContextAction.Provider>
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
