import useIntersectionObserver from "../../utils/InterSectionObserver";
import { useCursorContext } from "../../context/Cursor";
import React, { useEffect, useRef } from "react";
import "./image.style.scss";
import { onload } from "utils/functions/onload";

function Banner({ src }: { src: string }) {
  const imgRef = useRef<HTMLDivElement | null>(null);
  const imgInEntry = useIntersectionObserver(imgRef, { threshold: 0.3 });
  const { functions } = useCursorContext();
  useEffect(() => {
    if (
      imgInEntry?.boundingClientRect.top &&
      imgInEntry?.boundingClientRect.top < 0
    ) {
      imgRef.current?.classList.add("tepada");
      imgRef.current?.classList.remove("pastda");
    } else {
      imgRef.current?.classList.add("pastda");
      imgRef.current?.classList.remove("tepada");
    }
  }, [imgInEntry, imgRef]);
  return (
    <div
      className={"about-image  " + (imgInEntry?.isIntersecting ? "active" : "")}
      ref={imgRef}
    >
      <img className="lazy-load" src={src} alt="" {...functions} onLoad={onload} />
    </div>
  );
}

export default Banner;
