import React, { useEffect, useRef } from "react";
import Item from "./Item";
import courses, { CourseType } from "data/courses";
import { useQuery } from "react-query";
import jwtAxios from "auth/jwtAxios";
 

function ListItems({courses, setImage }: {courses:CourseType[]; setImage: any }) {
  const ref = useRef<HTMLDivElement | null>(null);

  const {data} = useQuery(["courses"] , ()=> jwtAxios.get(`${process.env.REACT_APP_API_URL}api/course/get-list`),{
    onSuccess:datas => {
      return datas?.data.map((data:any,index:number) => data.index = index + 1)
    }
  })

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => { 
    const parent = e.currentTarget;
    const listItemEls = document.getElementsByClassName(
      "list-item"
    ) as HTMLCollectionOf<HTMLDivElement>;

    Array.from(listItemEls).forEach((listItemEl) => {
      const topDistance =
        listItemEl.offsetTop - parent.offsetTop - parent.scrollTop;

      const bottomDistance =
        parent.scrollTop +
        parent.offsetHeight -
        listItemEl.offsetTop +
        parent.offsetTop -
        listItemEl.offsetHeight;
        listItemEl.classList.remove("active")

      listItemEl.style.translate =
        Math.abs((Math.round(topDistance) - Math.round(bottomDistance)) / 13) +
        "px";
    });

    const listItemEl = Array.from(listItemEls).reduce((contrainer, listItemEl) => {
       return parseInt(listItemEl.style.translate) <
        parseInt(contrainer.style.translate)
        ? listItemEl
        : contrainer;
    });
    listItemEl.click()
    listItemEl.classList.add("active")
   };
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 500;
    }
  }, []);


  console.log({data});
  

  return (
    <div className="list-items" onScroll={handleScroll} ref={ref}>
      {courses.map((course: CourseType) => (
        <Item course={course} key={course.id} setImage={setImage} />
      ))}
    </div>
  );
}

export default ListItems;
